.container {
    padding: 80px;
    background-color: var(--primary-color-yellow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    h2,
    h4,
    p {
        margin: 0px;
        text-align: center;
    }

    p {
        margin: 0px;
        max-width: 900px;
        width: 85%;

        i {
            width: 80%;
            font-size: 11px;
            color: var(--text-color-5);
        }
    }

    h1 {
        font-size: 42px;
        font-family: stellarBold;
        margin-bottom: 15px;
        color: var(--basic-white);
        margin-bottom: 50px;
    }

    .card {
        background-color: var(--basic-white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: var(--text-color-6);
        padding: 70px 50px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0px;
            max-width: 900px;
            width: 100%;
        }

        h2 {
            font-size: 36px;
            font-family: stellarBold;
            margin-bottom: 50px;
        }

        .oldValue {
            text-decoration: line-through;
            font-size: 20px;
            font-family: stellarLight;
            margin-bottom: 20px;
        }

        .newValue {
            font-size: 32px;
            font-weight: bolder;
            font-family: stellarBold;
            margin-bottom: 50px;
        }

        .button {
            width: 200px;
            border-radius: 50px;
            padding: 20px;
            background-color: var(--error-color-light);
            text-align: center;
            color: var(--basic-white);
            font-weight: bolder;
            cursor: pointer;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover {
                background-color: #c76565;
            }
        }
    }
}

@media screen and (max-width: 1054px) {
    .container {
        padding: 80px 0px;

        .card {
            width: 80%;
            padding: 70px 0px;
        }
    }
}

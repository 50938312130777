.container {
    height: 70px;
    background-color: var(--primary-color-rose-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--basic-white);
    font-size: 12px;

    a {
        color: var(--basic-white);
        text-decoration: none;
        margin-left: 7px;
        font-weight: bolder;
    }
}
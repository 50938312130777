.container {
    width: 200px;
    height: 60px;
    background: #009250;
    border-radius: 33px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--basic-white);
    font-weight: bolder;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
        margin-right: 10px;
    }
}

@media screen and (max-width: 1054px) {
    .container {
        width: 60px;

        img {
            margin-right: 0px;
        }

        p {
            display: none;
        }
    }
}

:root {
    // Basic Color Palette
    --basic-black: #1F1D1D;
    --basic-white: #ffffff;
    --basic-grey: #F6F6F6;
    --basic-background: #F5F5F5;

    // Color Palette | Primary
    --primary-color-green-dark: #545B55;
    --primary-color-green: #CCC7AE;
    --primary-color-yellow: #EED5B7;
    --primary-color-rose-dark: #D19793;
    --primary-color-rose: #F6CEC8;

    // Color Palette | Text
    --text-color-7: #1a1a1a;
    --text-color-6: #263238;
    --text-color-5: #37474f;
    --text-color-4: #455a64;
    --text-color-3: #78909c;
    --text-color-2: #cfd8dc;
    --text-color-1: #e8ecee;

    // Color Palette | Sucess
    --success-color-dark: #005024;
    --success-color-main: #006f38;
    --success-color-light: #009250;
    --success-color-background: #e1f3ea;

    // Color Palette | Warning
    --warning-color-dark: #eeab00;
    --warning-color-main: #fcc332;
    --warning-color-light: #ffd772;
    --warning-color-background: #FFF8E8;

    // Color Palette | Error
    --error-color-dark: #aa2929;
    --error-color-main: #eb5959;
    --error-color-light: #f97f7f;
    --error-color-background: #ffefef;
}


.modal {
    width: 100vw;
    height: 100vh;
    background-color: #2F9DFE;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login {
        max-width: 600px;
        width: 100%;
        background-color: white;
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        h2 {
            margin: 0px;
            margin-bottom: 40px;
        }

        .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .button {
            width: 200px;
            border-radius: 10px;
            padding: 15px 20px;
            margin-top: 40px;
            background-color: #2F9DFE;
            text-align: center;
            color: var(--basic-white);
            font-weight: bolder;
            cursor: pointer;
    
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
    
            &:hover {
                background-color: #2882d2;
            }
        }
    }

    .margin {
        margin: 10px;
    }
}

.container {
    padding: 80px;
    background-color: var(--primary-color-yellow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    h2,
    h4,
    p {
        margin: 0px;
        text-align: center;
        font-family: stellarBold;
    }

    p {
        margin: 0px;
        max-width: 900px;
        width: 85%;

        i {
            width: 80%;
            font-size: 11px;
            color: var(--text-color-5);
        }
    }

    h1 {
        font-size: 42px;
        font-family: stellarBold;
        margin-bottom: 15px;
        color: var(--basic-white);
        margin-bottom: 50px;
    }

    .button {
        width: 200px;
        border-radius: 50px;
        padding: 20px;
        background-color: var(--error-color-light);
        text-align: center;
        color: var(--basic-white);
        font-weight: bolder;
        cursor: pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
            background-color: #c76565;
        }
    }

    .modalPayment {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 99;

        .modal {
            max-width: 700px;
            width: 100%;
            max-height: 500px;
            height: 100%;
            background-color: white;
            z-index: 999;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 20px;
            padding: 20px 20px;

            .header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 15px;
                position: relative;

                h2 {
                    width: 100%;
                    text-align: center;
                }

                p {
                    margin-top: 10px;
                    font-size: 16px;
                }

                svg {
                    cursor: pointer;
                    position: absolute;
                    top: -15px;
                    right: -10px;
                }
            }

            .body {
                width: 90%;
                height: 380px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 auto;
                margin-top: 20px;
                overflow-y: scroll;
                padding: 0px 25px 0px 20px;

                h4 {
                    width: 100%;
                    text-align: start;
                    padding-bottom: 15px;
                }

                .row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                .margin {
                    margin: 10px;
                }
            }
        }
    }

    .card {
        background-color: var(--basic-white);
        border-radius: 10px;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: var(--text-color-6);
        padding: 70px 50px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0px;
            max-width: 900px;
            width: 100%;
        }

        h2 {
            font-size: 36px;
            font-family: stellarBold;
            margin-bottom: 50px;
        }

        .oldValue {
            text-decoration: line-through;
            font-size: 20px;
            font-family: stellarLight;
            margin-bottom: 20px;
        }

        .newValue {
            font-size: 32px;
            font-weight: bolder;
            font-family: stellarBold;
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: 800px) {
    .container {
        padding: 80px 0px;

        .card {
            width: 80%;
            padding: 70px 0px;
        }

        .modalPayment {
            .modal {
                width: 80%;
                padding: 20px;

                h2 {
                    margin-top: 10px;
                }

                .body {
                    width: 95%;
                    padding: 0px 15px 0px 10px;

                    .row {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 410px) {
    .container .modalPayment .modal .body {
        height: 330px;
        padding-bottom: 20px;
    }
}

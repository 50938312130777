.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 1054px) {
    .container {
    flex-direction: column;
    }
}